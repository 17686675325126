@import url('https://fonts.googleapis.com/css?family=Kanit:300,400,500');
body {
  margin: 0 !important;
  padding: 0 !important;
  font-family: "Kanit" !important ;
  background-color: #171c20 !important;
}

h1.header {
  font-size: small !important;
  text-align: center !important;
  color: white !important;
}

.header .logo {
  height: 40px;
  vertical-align: middle;
}

.bib {
  /* background-color: white; */
  color: white;
  width: 200px;
  text-align: center;
  margin: auto;
  /* height: 200px; */
  line-height: 150px;
  font-size: 48pt;
  /* border: 10px solid rgb(249, 124, 61); */
  /* box-shadow: 0 0 10px grey; */
  /* border-radius: 100px; */
  box-sizing: border-box;
  height: 120px;
}

.title {
  /* width: 300px; */
  background: rgb(249, 124, 61);
  color: white;
  /* margin: -84px auto; */
  padding: 6pt;
  text-align: center;
}

.content {
  background: white;
  min-height: 100vh;
  text-align: center;
}

.section {
  max-width: 480px;
  margin: auto;
}

.sectionTitle {
  margin: 1em;
  text-decoration: underline;
  text-align: center;
}

.sectionContent {
  text-align: center;
}

.runner{
  color: #ffffff;
  margin-top: 30px;
}

.follow{
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.ecerEslip{
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
}

.ecer{
  display: flex;
  justify-content: center;
  width: 50%;
}

.eslip{
  display: flex;
  justify-content: center;
  width: 50%;
}

.photoProfile{
  max-width: "100%"; 
  width: "200px"; 
  max-height: "100%"; 
  height: "200px"; 
  margin-top: '10px'; 
  margin-bottom: "-20px"; 
  border-radius: "50%"; 
  object-fit: "cover"; 
}

@media only screen and (max-width: 992px) {
  .ecerEslip{
    flex-direction: column
  }
  
  .ecer{
    width: 100%;
  }
  
  .eslip{
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
 
}

@media screen and (max-width: 425px){

}